import { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'

import type {
  CoursePanelQuery,
  CoursePanelQueryVariables,
  CoursePanelQuery_course as Course,
} from 'api/__generated__/CoursePanelQuery'
import type {
  UpdateCourseMutation,
  UpdateCourseMutationVariables,
} from 'api/__generated__/UpdateCourseMutation'

import * as queries from 'api/queries'
import * as mutations from 'api/mutations'
import { BigLoading } from 'components/shared/loading'
import Error from 'components/shared/error'
import SessionLink from 'components/SessionLink'
import { OrderTable } from 'components/panel/session-panel/components'
import { InlineButtonDanger, InlineButtonPrimary } from 'components/buttons'
import { RequireRole } from 'components/shared/auth-components'
import { Role } from 'globalTypes'

interface CourseTableProps {
  course: Course
}
export const CourseTable = ({
  course: { id, level, bookableCapacity, spaces, sessions, isAvailable },
}: CourseTableProps): JSX.Element => {
  const [updateCourse, { loading: saving }] = useMutation<
    UpdateCourseMutation,
    UpdateCourseMutationVariables
  >(mutations.UPDATE_COURSE)

  const updateAvailability = (isAvailable: boolean) => {
    return updateCourse({ variables: { courseId: id, isAvailable } })
  }

  const sortedSessions = sessions
    .map((session) => {
      const dt = DateTime.fromISO(session.starts)
      return { ...session, startsDT: dt }
    })
    .sort((a, b) => {
      return a.startsDT.toMillis() - b.startsDT.toMillis()
    })

  return (
    <>
      <h3 className="panel-heading">Lesson info</h3>
      <table className="min-w-full divide-y divide-gray-200 table-fixed">
        <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
          <tr>
            <th scope="col" className="th--col">
              Level
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm capitalize w-1/3">
              {level.toLowerCase()}
            </td>
          </tr>
          <tr>
            <th scope="col" className="th--col">
              Capacity
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm">
              {bookableCapacity}
            </td>
          </tr>
          <tr>
            <th scope="col" className="th--col">
              Spaces remaining
            </th>
            <td className="px-2 py-4 whitespace-nowrap text-sm">{spaces}</td>
          </tr>
          <RequireRole roles={[Role.ADMIN, Role.MANAGER]}>
            <tr>
              <th scope="col" className="th--col">
                Bookable?
              </th>
              <td className="px-2 py-4 whitespace-nowrap text-sm">
                {isAvailable ? (
                  <InlineButtonDanger
                    disabled={saving}
                    onClick={() => updateAvailability(false)}
                  >
                    Set un-available
                  </InlineButtonDanger>
                ) : (
                  <InlineButtonPrimary
                    disabled={saving}
                    onClick={() => updateAvailability(true)}
                  >
                    Set available
                  </InlineButtonPrimary>
                )}
              </td>
            </tr>
          </RequireRole>
        </tbody>
      </table>

      <h3 className="panel-heading">Sessions</h3>
      <table className="min-w-full divide-y divide-gray-200 table-fixed">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th px-2">
              Date
            </th>
            <th scope="col" className="th px-2 w-1/3">
              Time
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-gray-900">
          {sortedSessions.map((session) => {
            return (
              <tr key={session.id}>
                <td className="px-2 py-4 whitespace-nowrap text-sm capitalize">
                  <SessionLink session={session} className="link">
                    {session.startsDT.toFormat('EEEE d MMMM')}
                  </SessionLink>
                </td>
                <td className="px-2 py-4 whitespace-nowrap text-sm">
                  {session.startsDT.toLocaleString(DateTime.TIME_24_SIMPLE)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

interface Props {
  courseId: Course['id']
  close: () => void
  // This is a bodge to give these things to SessionPanel, so it can mount for animating and not have to flash
  setLoading: (loading: boolean) => void
  setRefetch: (refetch: (options?: any) => Promise<any>) => void
  setData: (data?: CoursePanelQuery) => void
}

const EditCoursePanel = ({
  courseId,
  setLoading,
  setRefetch,
  setData,
}: Props): JSX.Element | null => {
  const { data, loading, refetch, error } = useQuery<
    CoursePanelQuery,
    CoursePanelQueryVariables
  >(queries.COURSE_PANEL, {
    variables: { courseId },
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    setRefetch(refetch)
  }, [refetch])

  useEffect(() => {
    setData(data)
  }, [data])

  if (loading) {
    return <BigLoading loading />
  }
  if (error) {
    return <Error className="mt-6">{error.message}</Error>
  }

  if (!data) {
    return <Error className="mt-6">Oh dear</Error>
  }

  return (
    <div className="flex-1">
      <CourseTable course={data.course} />
      <OrderTable orders={data.orders ?? []} />
    </div>
  )
}

export default EditCoursePanel
